import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import config from '../../config';
import PropTypes from 'prop-types';

// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  ({ children, className, onClick }, ref) => (
    <button
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      className={className}
    >
      {children}
    </button>
  )
);

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      navbarclass: '',
      visibilityClass: '',
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
    this.setState({ navbarclass: 'mobilemenuopen' });
  };
  handleScroll = () => {
    const { visibilityClass } = this.state;
    if (window.pageYOffset > 600) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '' });
      }
    }
  };

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  render() {
    const { openMenu, visibilityClass, navbarclass } = this.state;
    return (
      <header>
        <nav
          className={`navbar navbar-expand-lg navbar-light ${this.props.textClass} ${visibilityClass}`}
          id="mainNav"
        >
          <a className="navbar-brand" href="/">
            <img
              className="img-fluid"
              src="/jukin-media/jukin-media-logo.png"
              alt={config.siteTitle}
            />
          </a>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler   ${
              this.props.MobileNavClass
            }  navbar-toggler-right ${openMenu ? 'opened' : 'collapsed'}`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${navbarclass} ${
              this.props.textClass
            } ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    as={CustomToggle}
                    className="nav-link"
                  >
                    Brands
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/people-are-awesome">
                      People Are Awesome
                    </Dropdown.Item>
                    <Dropdown.Item href="/fail-army">FailArmy</Dropdown.Item>
                    <Dropdown.Item href="/the-pet-collective">
                      The Pet Collective{' '}
                    </Dropdown.Item>
                    <Dropdown.Item href="/this-is-happening">
                      This Is Happening
                    </Dropdown.Item>
                    <Dropdown.Item href="/poke-my-heart">
                      Poke My Heart
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/ott">
                  OTT/CTV
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.jukinmedia.com/licensing"
                >
                  Video Licensing
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
Header.propTypes = {
  MobileNavClass: PropTypes.string,
};

Header.defaultProps = {
  MobileNavClass: 'white',
};
